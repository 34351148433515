import { IUserAction } from "../actions/user";
import { IUser } from "../interfaces/user";
import { IContext } from "../interfaces/store-action";
import { createContext, h } from "preact";
import { useMemo, useReducer } from "preact/hooks";
import { FlushPendingEvents } from "../hooks/analytics";

export interface IUserState {
  user: IUser;
}

const initialState: IUserState = {
  user: {
    isLoggedIn: null,
    id: null,
    userId: null,
    gluId: null,
    profile: {
    },
    identities: {
    },
    sessionId: null,
    version:null,
    referralLink:null,
    referredBy:null
  },
};

function userReducer(state: IUserState, action: IUserAction): IUserState {
  switch (action.type) {
    case "USER_STORE SET USER": {
      if (action.payload.user) {
        window["user_is_set"] = true;
        window["USER"] = action.payload.user;
        FlushPendingEvents();
        try {
          if (
            action.payload.user.userId === action.payload.user["anonymousId"]
          ) {
            action.payload.user.isLoggedIn = false;
          } else {
            action.payload.user.isLoggedIn = true;
          }
        } catch (e) {
          console.log(e);
        }
        return {
          ...state,
          user: action.payload.user,
        };
      }
    }
  }
}

export const UserContext =
  createContext<IContext<IUserState, IUserAction>>(undefined);

export function UserProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<IUserState, IUserAction>(
    userReducer,
    initialState
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}
