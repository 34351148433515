import { IUserAction, setUserAction } from "../actions/user";
import { IUserState, UserContext } from "../contexts/user";
import { IDispatch } from "../interfaces/dispatch";
import { IStatus } from "../interfaces/status";
import { fetchUser } from "../network/user";
import { useSession } from "./session";
import { useContext, useEffect } from "preact/hooks";
import { useStatus } from "./status";
import { withInternalError } from "../util/custom-errors";
import { useLogs } from "./logging";
import { IUser } from "src/interfaces/user";

export const useUser = (): [IUserState, IDispatch<IUserAction>] => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw withInternalError("useUser must be used within a SessionProvider", "useUser");
  }
  return [context.state, context.dispatch];
};

export const useUserInitilizer = (): [IStatus] => {
  const [status, setStatus] = useStatus();
  const [userState, userDispatch] = useUser();
  const [session] = useSession();
  const [recordError] = useLogs();

  useEffect(() => {
    if (session.token) {
      requestUser();
    }
  }, [session.token]);

  function parseJwt(token) {
    const base64Url = token.split('.')[1];

    // Convert Base64Url to Base64
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Decode Base64 into a Uint8Array
    const decodedData = Uint8Array.from(atobPolyfill(base64), c => c.charCodeAt(0));

    // Use TextDecoder to convert the bytes to string
    const jsonPayload = new TextDecoder().decode(decodedData);

    return JSON.parse(jsonPayload);
}

function atobPolyfill(base64) {
    return decodeURIComponent(Array.prototype.map.call(window.atob(base64), c =>
        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
}

  const requestUser = async () => {
    try {
      setStatus({ state: "LOADING" });
      if (!session.token) throw withInternalError("token not found", "useUserInitilizer.requestUser");
      const jwtToken = session.token;
      const decodedData = parseJwt(jwtToken);
      
      if (decodedData.version && decodedData.version === 'v2') {
        // Convert decoded data to IUser object
        const user: IUser = convertToIUser(decodedData);

        // Dispatch the user data without calling the fetchUser API
        userDispatch(setUserAction({ user }));
        setStatus({ state: "LOAD_SUCCESS" });
        
      }
      else{
      const user = await fetchUser(session.token);

      userDispatch(setUserAction({ user }));
      setStatus({ state: "LOAD_SUCCESS" });
      }
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "error fetching user"});
      recordError(e);
    }
  };

  return [status];
};

const convertToIUser = (decodedData: any): IUser => {
  return {
    id: decodedData.id,
    userName: decodedData.userName,
    email: decodedData.email,
    phone:decodedData.phone,
    client:decodedData.client,
    referralLink: decodedData.referralLink,
    referredBy: decodedData.referredBy,
    profile: decodedData.profile,
    isLoggedIn: decodedData.isLoggedIn,
    userId: decodedData.userId,
    gluId: decodedData.gluId,
    appVersion:decodedData.appVersion,
    identities: decodedData.identities,
    sessionId: decodedData.sessionId,
    version:decodedData.version
    
  };
};
